// dashboard
import Analytics from "pages/Dashboards/Analytics";

// Users
import UserListView from "pages/Users/ListView";

// Church
import ChurchListView from "pages/Churches/ListView";

// navbar
import NavBars from "pages/Components/Navigation/Navbars";
import NavTabs from "pages/Components/Navigation/Tabs";
import NavigationBreadcrumb from "pages/Components/Navigation/NavigationBreadcrumb";
import Pagination from "pages/Components/Navigation/Pagination";

import BasicTable from "pages/Components/Table/Basic";
import ReactDataTable from "pages/Components/Table/ReactTable";
import RemixIcon from "pages/Components/Icons/Remix";
import LucidIcon from "pages/Components/Icons/Lucide";
import MapsGoogle from "pages/Components/MapsGoogle";
import MapsLeaflet from "pages/Components/MapsLeaflet";

import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import UserProfile from "pages/Authentication/UserProfile";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
import Offline from "pages/AuthenticationInner/Offline";
import CategoriesList from "pages/categories";
import TopicList from "pages/topics";
import ArticlesList from "pages/articles";
import SingleArticle from "pages/articles/single-article";
import { CreateNewArticle } from "pages/articles/new";
import { PushNotification } from "pages/push-notification";
import { EmailManager } from "pages/email-manager";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  // { path: "/", component: ArticlesList },
  { path: "/dashboard", component: Analytics },

  // navigation
  { path: "/navigation-navbars", component: NavBars },
  { path: "/navigation-tabs", component: NavTabs },
  { path: "/navigation-breadcrumb", component: NavigationBreadcrumb },
  { path: "/navigation-pagination", component: Pagination },

  // Table
  { path: "/tables-basic", component: BasicTable },
  { path: "/tables-datatable", component: ReactDataTable },

  // Icons
  { path: "/icons-remix", component: RemixIcon },
  { path: "/icons-lucide", component: LucidIcon },

  // Map
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Users
  { path: "/view-users", component: UserListView },

  // Churches
  { path: "/view-churches", component: ChurchListView },

  { path: "/view-categories", component: CategoriesList },
  { path: "/view-topics", component: TopicList },
  { path: "/view-articles", component: ArticlesList },
  { path: "/view-articles/:articleSlug", component: SingleArticle },
  { path: "/new-article", component: CreateNewArticle },
  { path: "/notifications", component: PushNotification },
  { path: "/email-templates", component: EmailManager },

  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [
  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  { path: "/pages-offline", component: Offline },
  { path: "/pages-404", component: Pages404 },

  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
