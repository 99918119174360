import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getNotifications, updateNotification } from "services/notifications";
import { cn } from "utils/cn";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const PushNotification = () => {
  const [isNotificationLive, setIsNotificationLive] = useState(true);
  const [language, setLanguage] = useState<string | null>(null);
  const [languageContent, setLanguageContent] = useState<string | null>(null);

  const languages: Array<string> = [
    "english",
    "arabic",
    "bengali",
    "chinese",
    "french",
    "german",
    "hindi",
    "italian",
    "japanese",
    "persian_farsi",
    "portuguese",
    "romanian",
    "russian",
    "spanish",
    "swahili",
    "swedish",
    "tagalog",
    "tamil",
    "thai",
    "ukrainian",
    "urdu",
    "vietnamese",
    "persianFarsi",
  ];
  const { data: notifications } = useQuery({
    queryKey: ["push-notification"],
    queryFn: getNotifications,
    staleTime: Infinity,
  });

  const queryClient = useQueryClient();
  const updateNotificationAction = useMutation({
    mutationFn: updateNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["push-notification"] });
    },
  });

  useEffect(() => {
    if (notifications) {
      setIsNotificationLive(notifications.is_notification_live);
      if (language && notifications.notification[language]) {
        setLanguageContent(notifications.notification[language]);
      }
    }
  }, [notifications, language]);

  const handleSave = async () => {
    if (!language) {
      console.log("Please select a language.");
      return;
    }
    if (!languageContent) {
      console.log("Language content cannot be empty.");
      return;
    }

    const data: {
      notification: boolean;
    } & Partial<Record<Languages, string>> = {
      notification: isNotificationLive,
      [language]: languageContent,
    };

    try {
      await updateNotificationAction.mutateAsync(data);
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  return (
    <div className="w-full h-full bg-white p-10 flex flex-col items-start gap-6">
      <h5 className="text-15">Push Notification</h5>
      <div className="w-full flex items-center justify-between">
        <select
          onChange={(e) =>
            setLanguage(e.target.value !== "" ? e.target.value : null)
          }
          className="w-64 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50 capitalize"
        >
          <option value={""}>Select Language</option>
          {languages.map((lang, index) => (
            <option key={index} value={lang} className="capitalize">
              {lang}
            </option>
          ))}
        </select>
        <div
          className={cn(
            "w-24 h-10 rounded-full relative transition-all duration-500 ease-in shadow-md",
            {
              "bg-gray-900 border-0": isNotificationLive,
              "bg-gray-100": !isNotificationLive,
            },
          )}
        >
          <span
            className={cn(
              "size-6 rounded-full absolute top-1/2 -translate-y-1/2 transition-all duration-500 ease-in",
              {
                "left-16 bg-white": isNotificationLive,
                "left-2 bg-gray-900": !isNotificationLive,
              },
            )}
            onClick={() => setIsNotificationLive((prev) => !prev)}
          />
        </div>
      </div>
      <div className="w-full">
        {language === null ? (
          <p>Select a language to continue</p>
        ) : (
          <div className="w-full flex flex-col items-end gap-6">
            <ReactQuill
              theme="snow"
              value={languageContent as string}
              onChange={setLanguageContent}
              className="w-full"
              modules={{ toolbar: ["bold", "italic", "underline"] }}
            />
            <button
              className="px-4 py-2 rounded bg-blue-700 hover:bg-blue-800 text-blue-100 font-semibold"
              disabled={updateNotificationAction.isPending}
              onClick={handleSave}
            >
              {updateNotificationAction.isPending ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
