import { PictureInPicture2 } from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "articles",
    label: "Articles",
    link: "/view-articles",
    icon: <PictureInPicture2 />,
  },
  // {
  //   id: "dashboard",
  //   label: "Dashboards",
  //   link: "/dashboard",
  //   icon: <MonitorDot />,
  // },
  {
    id: "landing",
    label: "Churches",
    link: "/view-churches",
    icon: <PictureInPicture2 />,
  },
  {
    id: "categories",
    label: "Categories",
    link: "/view-categories",
    icon: <PictureInPicture2 />,
  },
  {
    id: "topics",
    label: "Topics",
    link: "/view-topics",
    icon: <PictureInPicture2 />,
  },
  {
    id: "users",
    label: "Users",
    link: "/view-users",
    icon: <PictureInPicture2 />,
  },

  {
    id: "notification",
    label: "Push Notifications",
    link: "/notifications",
    icon: <PictureInPicture2 />,
  },
  {
    id: "email",
    label: "Email Manager",
    link: "/email-templates",
    icon: <PictureInPicture2 />,
  },
];

export { menuData };
