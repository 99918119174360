import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TextInput } from "Common/Components/form/input";
import { Modal } from "Common/Components/modal-2";
import { FormEvent, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createTemplate,
  editTemplate,
  getSingleTemplate,
} from "services/email-template";

type AddEditTemplateProps = {
  id?: number;
  open: boolean;
  close: VoidFunction;
};

export const AddEditTemplate = ({ id, open, close }: AddEditTemplateProps) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();
  // get template details
  const { data: template, isLoading } = useQuery({
    queryKey: ["email-templates", id],
    queryFn: () => getSingleTemplate(id as number),
    enabled: !!id,
    staleTime: Infinity,
  });
  // create template
  const createTemplateAction = useMutation({
    mutationFn: createTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["email-templates"] });
      close();
    },
  });
  // update template
  const updateTemplateAction = useMutation({
    mutationFn: editTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["email-templates", id] });
      close();
    },
  });

  useEffect(() => {
    if (template) {
      setMessage(template.body);
    }
  }, [template]);

  const handleCreateUpdateTemplate = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = (formData.get("name") as string) || "";
    const subject = (formData.get("subject") as string) || "";

    if (!id && name.trim().length === 0) {
      setError("Template Name is required");
      return;
    }

    if (subject.trim().length === 0) {
      setError("Template Subject is required");
      return;
    }
    if (message.trim().length === 0) {
      setError("Template Message is required");
      return;
    }
    if (id) {
      await updateTemplateAction.mutateAsync({
        id,
        data: {
          body: message,
          subject,
        },
      });
    } else {
      await createTemplateAction.mutateAsync({ name, subject, body: message });
    }
    setError(null);
  };

  return (
    <Modal
      open={open}
      close={() => {
        setError(null);
        setMessage("");
        close();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {id ? `Edit ${template ? template.name : ""}` : "Create"} Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full p-2 rounded bg-amber-100 flex items-center gap-3">
          <p className="text-sm text-amber-700">Variables:</p>
          <div className="flex items-center gap-1.5 flex-wrap">
            <code className="text-xs text-amber-100 p-0.5 rounded-sm flex items-center justify-center bg-amber-800">
              {"{{username}}"}
            </code>
            ,
            <code className="text-xs text-amber-100 p-0.5 rounded-sm flex items-center justify-center bg-amber-800">
              {"{{email}}"}
            </code>
            ,
            <code className="text-xs text-amber-100 p-0.5 rounded-sm flex items-center justify-center bg-amber-800">
              {"{{first_name}}"}
            </code>
            ,
            <code className="text-xs text-amber-100 p-0.5 rounded-sm flex items-center justify-center bg-amber-800">
              {"{{last_name}}"}
            </code>
          </div>
        </div>
        {isLoading ? (
          <p>Loading template...</p>
        ) : (
          <form
            onSubmit={handleCreateUpdateTemplate}
            className="w-full h-auto flex flex-col gap-4"
          >
            {!id ? (
              <TextInput
                label="Name"
                name="name"
                placeholder="Enter template name"
              />
            ) : null}
            <TextInput
              label="Subject"
              name="subject"
              defaultValue={template?.subject}
              placeholder="Enter email subject"
            />
            <ReactQuill
              theme="snow"
              value={message}
              onChange={(value) => {
                setMessage(value);
              }}
              modules={{
                toolbar: [
                  [
                    "bold",
                    "italic",
                    "underline",
                    "link",
                    "image",
                    { list: "ordered" },
                    { list: "bullet" },
                  ],
                ],
              }}
              formats={["bold", "italic", "underline"]}
              placeholder="Enter template message"
            />
            <button
              type="submit"
              className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800 disabled:bg-opacity-50"
              disabled={
                createTemplateAction.isPending || updateTemplateAction.isPending
              }
            >
              {id ? "Update" : "Create"} Template
            </button>

            {error ? (
              <p className="text-red-500 text-sm font-medium">{error}</p>
            ) : null}
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
